.qld-spinner {
    text-align: left;
    display: flex;
    align-items: center;

    .spinner-border {
        color: #9ac02c;
        width: 30px;
        height: 30px;
        margin-right: 1rem;
    }

    .qld-spinner-label {
        font-size: 1.125rem;
        font-weight: 600;
        color: inherit;
        font-family: inherit;
        font-weight: 600;
        line-height: 1.3;
        display: block;
    }

    &.align-center {
        text-align: center;
        display: block;

        .spinner-border {
            margin-right: 0;
        }

        .qld-spinner-label {
            margin-top: 0.75rem !important;
        }
    }
}
