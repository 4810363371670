.qld-banner {

    //Defaults variables
    --bg-color: var(--qld-light-grey);
    --title-color: var(--headings-color);
    --lead-color: var(--body-color);
    --max-width: 1600px;

    // Variants (text colour and backgrounds)
    &.alt {
        --bg-color: var(--qld-alt-grey);
        --bg-texture: url(assets/img/banner-texture-tile-light.png);
    }

    &.dark,
    &.dark-alt {
        --title-color: var(--qld-white);
        --lead-color: var(--qld-white);

        .breadcrumb {
            //Redfine variables already scoped in .breadcrumbs
            //These new values will only be scoped to breadcrumbs within the .banner-* class
            --breadcrumb-color: var(--qld-white);
            --qld-breadcrumb-divider-color: var(--qld-white);
            --qld-breadcrumb-item-active-color: var(--qld-white); 
            --qld-link-color-rgb: var(--qld-white);
        }
    }

    &.dark {
        --bg-color: var(--qld-primary);
        --bg-texture: url(assets/img/banner-texture-tile-dark.png);
    }

    &.dark-alt {
        --bg-color:var(--qld-dark-alt-background); //Todo, add this colour to variables.scss (?) Was defined in Figma. 
    }

    //Padding
    --padding-top: 3rem;
    --padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        --padding-top: 3rem;
        --padding-bottom: 3rem;
    }
}


//Component
.qld-banner {

    background-color: var(--bg-color);
    padding: var(--padding-top) 0 var(--padding-bottom) 0;
    position: relative;
    max-width: var(--max-width);

    &.with-pattern {
        background-image: var(--bg-texture);
        background-repeat: repeat;
    }

    .banner-title {
        // Line height (52px) / Font-size (40px) = 1.3
        line-height: 1.3;
        color: var(--title-color);
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        max-width: 90%;
    }

    .banner-lead {
        font-size: 1.5rem;
        line-height: 2rem;
        max-width: 50rem;
        color: var(--lead-color);
    }

    .breadcrumb {
        color: var(--breadcrumb-color);
        --qld-breadcrumb-padding-y: 0;
    }

    .banner-cta {
        margin-top: 1.5rem;

        .btn {
            margin-right: 0.75rem;
        }
    }


    //Image
    .banner-image {
        // --image-height: calc(100% + var(--padding-top) + var(--padding-bottom));
        // width: calc(var(--image-height)/1.33);
        // height: var(--image-height);
        position: absolute;
        top: 0;
        right: 0;
        left: 60%;
        height: 100%;
        background: var(--background-img);
        background-size: cover;
        background-position: center center;

        //Optional - Add an angled edge to image, with ".banner-image .banner-image--angle" 
        &--angle {
            --banner-angle: 20%; //(angle in degrees)
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, var(--banner-angle) 100%);
        }

    }

    //Hide banner image below tablet
    @include media-breakpoint-down(lg) {
        .banner-image {
            display: none;
        }
    }

    //Stack buttons at very small viewports
    @include media-breakpoint-down(sm) {
        .banner-cta {
            flex-direction: column;
            .btn {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
            }
        }
    }

}