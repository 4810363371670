.tag-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    padding: 30px;

    .tag-item {
        border-color: $qld-light-grey;
        color: $qld-dark-grey-muted;
    }

    .tag-item {
        margin: 0 0.5rem 0.5rem 0;
        list-style-type: none;
        display: inline-block;
        border-radius: 16px;
        padding: 0 0.5rem;
        border-width: 1px;
        border-style: solid;
        font-size: 0.875rem;
        line-height: 1.5rem;
        text-align: center;

        &:focus-within {
            outline: 2px solid var(--qld-focus-color);
            outline-offset: 2px;
            border-radius: 16px;
        }

        &.tag-large {
            margin: 0 1rem 1rem 0;
            line-height: 1.5;
            font-size: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 32px;
        }

        &.tag-link {
            color: $qld-sapphire-blue;
            border-color: $qld-sapphire-blue;

            a {
                text-decoration: none;

                &:focus {
                    outline: none;
                }
            }

            &:hover,
            &:active,
            &:focus {
                background-color: $qld-textbox-border-color;
                color: $qld-white;
                text-decoration: underline;
                text-underline-offset: var(--qld-link-underline-offset);

                a {
                    color: $qld-white;
                }
            }
        }

        &.tag-link:not(.tag-large) {

            &:hover,
            &:active,
            &:focus {
                text-underline-offset: 0.1rem;
            }
        }

        &.tag-info {
            color: $qld-dark-grey-muted;
            border: 0;
            background-color: $qld-extra-light-grey;
        }
    }

    &.tag-dark {
        background-color: $qld-sapphire-blue;

        .tag-item {
            color: $qld-dark-text;
        }

        .tag-link {
            color: $qld-white;
            border-color: $qld-white;

            a {
                color: white;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: $qld-light-green-dark;
                border-color: $qld-light-green-dark;

                a {
                    color: $qld-text-darkest;
                }
            }
        }

        .tag-info {
            background-color: $qld-color-dark-background--shade;
        }
    }

    &.tag-alt {
        background-color: $qld-light-grey-alt;

        .tag-item {
            color: $qld-dark-grey-muted;
            border-color: $qld-soft-grey;
        }

        .tag-link:hover,
        .tag-link:active,
        .tag-link:focus {
            color: $qld-white !important;
        }

        .tag-link {
            color: $qld-sapphire-blue;
            border-color: $qld-sapphire-blue;
        }
    }

    &.tag-dark-alt {
        background-color: $qld-dark-blue;

        .tag-item {
            a {
                color: $qld-dark-text;
            }

            color: $qld-dark-text;
            border-color: $qld-dark-border;
        }

        .tag-info {
            background-color: $qld-dark-blue-shade;
        }

        .tag-link {

            &:hover,
            &:active,
            &:focus {
                background-color: $qld-light-green-dark;
                border-color: $qld-light-green-dark;

                a {
                    color: $qld-text-darkest;
                }
            }
        }
    }
}

$close-icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M180.7 180.7C186.9 174.4 197.1 174.4 203.3 180.7L256 233.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L278.6 256L331.3 308.7C337.6 314.9 337.6 325.1 331.3 331.3C325.1 337.6 314.9 337.6 308.7 331.3L256 278.6L203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3C174.4 325.1 174.4 314.9 180.7 308.7L233.4 256L180.7 203.3C174.4 197.1 174.4 186.9 180.7 180.7zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z'/%3E%3C/svg%3E";
$close-icon-hover: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z'/%3E%3C/svg%3E";

.close-icon {
    mask-image: url($close-icon);
    background-color: $qld-dark-green;
    height: 1.25rem;
    width: 1.25rem;
    vertical-align: middle;
    border: none;
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover {
        background-color: $qld-alt-button-hover;
        mask-image: url($close-icon-hover);
    }
}
