// ----------------------------------------------------------------------------------------------------------------------
// Footer Area

@import "./colours";
@import "./measurements";

.qld-footer {
  padding-block: var(--#{$prefix}footer-padding-y);
  border-top: solid;
  border-top-width: calc(var(--#{$prefix}footer-special-border-width) + 1px);
  border-top-color: var(--#{$prefix}footer-color-designAccent);
  background: var(--#{$prefix}footer-color-background);
  color: var(--#{$prefix}footer-color-text);
  font-size: var(--#{$prefix}footer-font-size);

  a {
    --qld-link-color-rgb: var(--#{$prefix}footer-color-link);
    &:hover {
      color: var(--#{$prefix}footer-color-focus);
    }
    &:visited {
      color: var(--#{$prefix}footer-color-link);
    }
  }
  .footer-site-name {
    font-size: calc(var(--#{$prefix}footer-spacing) * 1.25);
    line-height: 1.5;
    font-weight: 600;
    margin-block-end: calc(var(--#{$prefix}footer-spacing) * 2);
    display: block;
    color: var(--#{$prefix}footer-color-title);
  }
  .footer-column {
    position: relative;
    margin-block-end: var(--#{$prefix}footer-spacing);
    margin-inline-end: 0;
  }
  @include media-breakpoint-down(lg) {
    .footer-column {
      margin-block-start: calc(var(--#{$prefix}footer-spacing) * 2);
    }
  }

  //Bordered columns in the footer should only show above medium breakpoint
  .border-column {
    height: 100%;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: calc(var(--#{$prefix}footer-special-border-width) - 1px);
      background: var(--#{$prefix}footer-column-border-color);
      top: 0;
      bottom: 0;
      inset-inline-start: calc(var(--#{$prefix}footer-spacing) * -2);
    }
  }
  .footer-heading {
    color: var(--#{$prefix}footer-color-title);
    font-size: calc(var(--#{$prefix}footer-font-size) * 1.25);
    margin-block-end: var(--#{$prefix}footer-spacing);
    line-height: 1.5;
    font-weight: 600;
  }
  .footer-contact-item {
    display: flex;
    align-items: center;
    margin-block-end: calc(var(--#{$prefix}footer-spacing) * 1.25);
    .qld-icon {
      --#{$prefix}icon-color: var(--#{$prefix}brand-accent);
      display: block;
      width: var(--#{$prefix}footer-social-icon-size-width);
      min-width: var(--#{$prefix}footer-social-icon-size-width);
      margin-inline-end: calc(var(--#{$prefix}footer-spacing) * 0.5);
      background-color: var(--#{$prefix}icon-color);
      --qld-brand-accent: var(--#{$prefix}footer-color-alt-button);
      &:hover {
        --qld-brand-accent: var(--#{$prefix}footer-color-alt-button__hover);
      }
    }
  }
  .footer-crest {
    display: block;
    width: 100%;
    max-width: var(--#{$prefix}footer-crest-max-width);
    margin-block-end: calc(var(--#{$prefix}footer-spacing) * 1.5);
    svg {
      width: 100%;
    }
  }

  //Footer Nav classes
  .nav {
    --#{$prefix}nav-link-color: var(--#{$prefix}footer-color-link);
    &.footer-link-list {
      a.nav-link {
        font-weight: 400;
        margin-block-end: calc(var(--#{$prefix}footer-spacing) * 0.7);
        padding: 0;
        &:hover {
          color: var(--#{$prefix}footer-color-focus);
        }
        &:first-child {
          margin-block-start: calc(var(--#{$prefix}footer-spacing) * 0.5);
        }
        &:focus-visible {
          box-shadow: 0 0 0 3px var(--#{$prefix}footer-color-border);
        }
      }
      &--social .nav-link {
        display: flex;
        margin-block-end: calc(var(--#{$prefix}footer-spacing) * 1.2);
        svg {
          fill: var(--#{$prefix}footer-color-crest-fill);
          width: var(--#{$prefix}footer-social-icon-size-width);
          max-height: var(--#{$prefix}footer-social-icon-size-height);
          margin-inline-end: calc(var(--#{$prefix}footer-spacing) * 0.75);
        }
        &:hover {
          --component-icon-color: var(--#{$prefix}footer-color-crest-fill);
        }
      }
    }
  }
  .footer-crest svg path {
    fill: var(--#{$prefix}footer-color-crest-fill);
  }
  .btn {
    --#{$prefix}btn-border-color: var(--#{$prefix}footer-color-alt-button);
    --#{$prefix}btn-hover-border-color: var(--#{$prefix}footer-color-alt-button);
    --qld-brand-accent: var(--#{$prefix}footer-color-alt-button);

    &:hover {
      --qld-brand-accent: var(--#{$prefix}footer-color-alt-button__hover);
    }
  }
  .footer-contact {
    .btn {
      min-width: 200px; // magic number
      margin-block-start: calc(var(--#{$prefix}footer-spacing) * 0.5);
    }
  }
  .container > .row > div {
    padding-inline: var(--#{$prefix}footer-column-spacing);
  }

  @media (max-width: 991.98px) {
    --#{$prefix}last-para-margin: 24px;
    --qld-footer-padding-y: 0;

    padding-top: calc(var(--gap) * 2);
    .footer-site-name {
      margin-block-end: calc(var(--#{$prefix}footer-gap) * 2);
    }
    .container {
      > .row {
        --qld-gutter-x: calc(var(--#{$prefix}footer-gap) * 2);

        > .col-md-12 {
          border-top: calc(var(--#{$prefix}footer-special-border-width) - 1px) solid
            var(--#{$prefix}footer-color-border);

          padding-inline: 0;
          margin-inline-start: var(--qld-gutter-x);
          width: calc(100% - calc(var(--qld-gutter-x) * 2));
          .border-column {
            &:before {
              content: "";
              content: none;
              background: none;
            }
            height: auto;
          }
          .footer-column {
            margin-block: calc(var(--#{$prefix}footer-gap) * 2);
            margin-inline-end: 0;
          }
        }
        > .col-md-12:first-of-type {
          border-top: none;
        }
        > .title {
          border-bottom: calc(var(--#{$prefix}footer-special-border-width) - 1px) solid
            var(--#{$prefix}footer-color-border);
          padding-inline: var(--qld-gutter-x);
        }
        > .crest {
          border-top: calc(var(--#{$prefix}footer-special-border-width) - 1px) solid
            var(--#{$prefix}footer-color-border);
          padding-inline: var(--qld-gutter-x);
          margin-inline: 0;
          width: 100%;
        }
        p:last-child {
          margin-block-end: 0;
        }
      }
    }
    .btn-outline-secondary {
      width: 100%;
    }
    .footer-link-list {
      display: block;
      column-count: 3;
      a.nav-link,
      a.nav-link:first-child {
        margin: 0 !important;
        margin-block-end: calc(var(--#{$prefix}footer-font-size) * 0.5) !important;
      }
    }
  }
  @media (max-width: 767.98px) {
    .container {
      > .row {
        > .col-md-12 {
          margin-inline-start: var(--qld-gutter-x);
          width: calc(100% - calc(var(--qld-gutter-x) * 2));
        }
        > .title,
        > .crest {
          padding-inline: var(--qld-gutter-x);
          margin-inline: 0;
          width: 100%;
        }
        p:last-child {
          margin-block-end: var(--#{$prefix}last-para-margin);
        }
      }
    }
    .footer-link-list {
      column-count: 2;
      width: 100%;
    }
  }
}

#qg-feedback-toggle {
  --qld-dark-alt-border: var(--#{$prefix}formIO-input-border);
  color: var(--#{$prefix}formIO-formio-colour);
  width: 100%;

  #btn-footer-feedback {
    color: var(--#{$prefix}footer-color-text);
    border: var(--#{$prefix}footer-special-border-width) solid var(--#{$prefix}footer-color-alt-button);
    width: 100%;
    font-weight: normal;
    &:active {
      color: var(--#{$prefix}formIO-formio-colour);
    }
  }
  #btn-footer-feedback:not(.collapsed) {
    border-radius: var(--#{$prefix}footer-border-radius);
    font-weight: var(--#{$prefix}formIO-feeback-font-weight);
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    outline: none !important;
    width: 100%;
    text-align: start;
    background-color: var(--#{$prefix}formIO-bg-colour);
    color: var(--#{$prefix}formIO-formio-colour);
    border-color: var(--#{$prefix}formIO-bg-colour);
    border-start-start-radius: var(--#{$prefix}footer-border-radius);
    border-start-end-radius: var(--#{$prefix}footer-border-radius);
  }
  .btn.qg-feedback-toggle {
    font-weight: bold;
  }
}

#qg-footer-feedback {
  background-color: var(--#{$prefix}formIO-bg-colour);
  a {
    color: var(--#{$prefix}formIO-link-colour);
    &:hover {
      color: var(--#{$prefix}formIO-link-colour-hover);
    }
  }
  border-radius: var(--#{$prefix}footer-border-radius);
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  padding-inline: var(--#{$prefix}footer-bootstrap-default-padding);
  padding-block-end: var(--#{$prefix}footer-bootstrap-default-padding);

  .qg-footer-feedback-footer {
    border-block-start: var(--#{$prefix}footer-border-width) solid var(--#{$prefix}formIO-hr-colour);
    padding-block-start: var(--#{$prefix}footer-spacing);

    a.qg-footer-feedback__close {
      border: var(--#{$prefix}footer-special-border-width) solid var(--#{$prefix}formIO-btn-close-colour);
      width: 100%;
      display: block;
      border-radius: var(--#{$prefix}footer-border-radius);
      padding: 9px 24px; // magic numbers
      text-align: center;
      text-decoration: none;
      &:hover {
        border-color: var(--#{$prefix}formIO-btn-close-colour__hover);
        text-decoration: underline;
        text-decoration-thickness: var(--#{$prefix}footer-font-underline-boarder);
      }
    }
  }
  #feedbackFormIO {
    .qg-spinner {
      margin-block-end: var(--#{$prefix}footer-spacing);
    }
    .form-check-input {
      margin-left: 0;
      --qld-form-check-bg: var(--#{$prefix}formIO-bg-colour);
      &:hover,
      &:focus {
        background-color: var(--#{$prefix}formIO-bg-colour);
      }
    }
    .form-check-input[type="radio"]:checked {
      background-image: url("data:image/svg+xml,%3csvg viewBox='-2 -2 4 4' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle r='1.8' fill='%23414141'/%3e%3c/svg%3e");
    }
    .formio-component-fieldSet2 {
      margin-block-end: 0;
    }
    .col-form-label {
      margin-block-end: calc(var(--#{$prefix}footer-spacing) * 0.5);
      font-weight: bold;
    }
    .formio-component-submit {
      margin-block-start: var(--#{$prefix}footer-spacing);
    }
    .form-check {
      align-items: flex-start;
    }
    .formio-modified .form-check-input,
    .formio-modified .form-control {
      border-color: var(--#{$prefix}formIO-border-notify-success);
      outline: var(--#{$prefix}formIO-border-outline-width) solid var(--#{$prefix}formIO-border-notify-success);
    }
    .formio-component-submit .btn,
    .formio-component-submit .btn-primary {
      --qld-btn-active-bg: var(--#{$prefix}formIO-submit-btn);
      --qld-btn-color: var(--#{$prefix}formIO-submit-btn-text);
      --qld-btn-border-color: transparent;
      --qld-btn-bg: var(--#{$prefix}formIO-submit-btn);
      width: 100%;
      &:hover,
      &:active {
        color: var(--#{$prefix}formIO-submit-btn-text);
        border-color: var(--#{$prefix}formIO-submit-btn-hover);
        text-decoration-line: underline;
        text-decoration-thickness: var(--#{$prefix}formIO-form-control-border-width);
        text-underline-offset: var(--#{$prefix}footer-text-underline-offset);
        background-color: var(--#{$prefix}formIO-submit-btn-hover);
      }
      &:disabled {
        color: var(--#{$prefix}formIO-formio-colour);
        background-color: var(--#{$prefix}formIO-btn-disabled-colour);
      }
    }
    .form-radio {
      .form-check {
        display: block;
        padding: 0;
        margin: 0;
        .form-check-label {
          display: flex;
          align-items: center;
          margin-block-end: calc(var(--#{$prefix}footer-spacing) * 0.5);
          margin-inline-start: 0;
          span {
            margin-inline-start: var(--#{$prefix}footer-spacing);
          }
        }
        .form-check-input {
          margin-left: 0;
        }
      }
    }
    .formio-component-howSatisfiedAreYouWithYourExperienceToday {
      flex-direction: column;
      padding-inline-start: 0;

      label.col-form-label {
        margin-inline-start: 0;
      }
      .form-check {
        display: block;
        margin-block-end: calc(var(--#{$prefix}footer-spacing) * 0.75);
        span {
          margin-block-end: 0;
        }
      }
    }
    .form-control {
      background-color: var(--#{$prefix}formIO-bg-colour);
      color: var(--#{$prefix}formIO-formio-colour);
      border: var(--#{$prefix}formIO-form-control-border-width) solid var(--#{$prefix}formIO-input-border);
    }
    .formio-modified .is-invalid {
      outline: var(--#{$prefix}formIO-border-notify-invalid) solid var(--#{$prefix}formIO-form-control-border-width);
      border-color: var(--#{$prefix}formIO-border-notify-invalid);
      outline-offset: 0;
    }
    .callout {
      margin-block: var(--#{$prefix}footer-spacing);
      background-color: var(--#{$prefix}formIO-callout-bg-colour);
      border-left: none;
      border-inline-start: calc(var(--#{$prefix}formIO-form-control-border-width) * 2) solid
        var(--#{$prefix}formIO-callout-border-colour);
      #feedback-serv-dep-staff {
        color: var(--#{$prefix}formIO-formio-colour);
        h4 {
          color: var(--#{$prefix}formIO-formio-colour);
          font-size: var(--#{$prefix}footer-font-size);
          margin-block: 0;
          position: relative;
          line-height: var(--#{$prefix}formIO-callout-heading-line-height);
        }
        p {
          margin-block-start: var(--#{$prefix}footer-spacing);
          margin-block-end: 0;
        }
      }
    }
    .formio-component-html1 {
      margin-block-start: calc(var(--#{$prefix}footer-spacing) * -1);

      h2 {
        margin-block-start: 0;
        color: var(--#{$prefix}formIO-thankyou-page-title-colour);
        font-size: var(--#{$prefix}footer-spacing);
        font-weight: bolder;
        margin-block-end: var(--#{$prefix}footer-spacing);

        &:first-of-type {
          border-block-end: var(--#{$prefix}footer-border-width) solid var(--#{$prefix}formIO-hr-colour);
          padding-block-end: 13px; // magic number
        }
      }
      p:last-of-type {
        display: none;
      }
    }
    button.btn-wizard-nav-submit {
      display: none;
    }
    .formio-wizard-nav-container {
      display: none;
    }
    .formio-errors .error {
      margin-block-end: var(--#{$prefix}footer-spacing);
    }
  }
}
