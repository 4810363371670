//PREP REUSABLE VARIABLES, SCOPED TO .blockquote
.blockquote {

    //DEFAULT
    --#{$prefix}quote-padding: 1.5rem 1rem 1.5rem 1.5rem;
    --#{$prefix}quote-border-color: var(--qld-syntax-values);
    --#{$prefix}quote-text-color: var(--qld-light-text-text);
    --#{$prefix}quote-font-size: 1.25rem;
    --#{$prefix}quote-line-height: 1.4;
    --#{$prefix}quote-border-width: 0.25rem;
}

//Ruleset for COMPONENT, reference our scoped variables, or use variables of ::root node
.blockquote {
    border-left: solid var(--#{$prefix}quote-border-width) var(--#{$prefix}quote-border-color);
    padding: var(--#{$prefix}quote-padding);

    blockquote {

        max-width: 54rem;
        margin-bottom: 0;
        color: var(--#{$prefix}quote-text-color);
        font-size: var(--#{$prefix}quote-font-size);
        line-height: var(--#{$prefix}quote-line-height);

        p:last-child {
            margin-bottom: 0;
        }
    }

    .quote-source {
        color: var(--qld-dark-grey-muted);
        font-size: 0.875rem;
        margin-top: 0.625rem;
    }
}