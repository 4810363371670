// GLOBALLY SCOPED VARIABLES
// These variables are avaiable to the full project and all components. (This file is included at the top of main.scss to ensure this)

// These variables include new $variables for the project, or replace existing out-of-the-box bootstrap variables defined under /node_modules/bootstrap/.../_variables.scss,

//QGDS tokens - masterbrand
@import "@qld-gov-au/qgds-tokens/dist/scss/_variables.scss";
@import "@qld-gov-au/qgds-tokens/dist/scss/_map.scss";

//QLD Names Colours
$qld-dark-blue : $core-default-color-brand-primary-dark-blue;
$qld-sapphire-blue : $core-default-color-brand-primary-sapphire-blue;
$qld-light-blue : $core-default-color-brand-primary-light-blue;
$qld-light-green : $core-default-color-brand-primary-light-green;
$qld-dark-green : $core-default-color-brand-secondary-darkgreen;
$qld-golden-yellow : $core-default-color-brand-secondary-golden-yellow;
$qld-maroon-traditional : $core-default-color-brand-other-gov-maroon;
$qld-maroon-modern : $core-default-color-brand-other-modern-maroon;
$qld-dark-text: $core-default-color-neutral-white;
$qld-dark-border: $color-default-color-dark-border-default;
$qld-dark-alt-border: $color-default-color-dark-border-alt;
$qld-alt-button-hover: $color-default-color-light-action-secondary-hover;
$qld-general-info-alert: $core-default-color-status-info-lighter;
$qld-red-border: $core-default-color-status-error-darker;
$qld-blue-border: $color-default-color-light-border-light;
$qld-yellow-border: $core-default-color-status-caution-darker;
$qld-dark-blue-shade : $color-default-color-dark-background-alt-shade;
$qld-light-background: $core-default-color-status-info-lightest;
$qld-light-background-shade: $color-default-color-light-background-light-shade;
$qld-light-border: $color-default-color-light-border-default;
$qld-dark-focus: $color-default-color-dark-focus-default;
$qld-link-visited: $color-default-color-light-underline-visited;
$qld-link-visited-dark: $color-default-color-dark-underline-visited;
$qld-text-light-blue-dark : $core-default-color-neutral-lighter;
$qld-light-green-dark : $core-default-color-brand-primary-light-green;
$qld-dark-green-dark : $color-default-color-dark-action-primary;
$qld-light-yellow : $core-default-color-status-caution-lightest;
$qld-button-dark-blue : $color-default-color-light-action-primary-hover;
$qld-text-darkest : $color-default-color-dark-link-on-action;
$qld-dark-hover : $color-default-color-dark-background-default-shade;

//QGOV Brand Mapping - QLD.GOV.AU
$qld-brand-primary-dark : $qld-dark-blue;
$qld-brand-primary : $qld-sapphire-blue;
$qld-brand-primary-light : $qld-light-blue;
$qld-brand-secondary-dark : $qld-dark-green;
$qld-brand-secondary : $qld-light-green;
$qld-brand-accent : $qld-golden-yellow;

//Grays
$qld-system-darkest : $core-default-color-neutral-darkest;
$qld-hint-text-color: $core-default-color-neutral-dark;
$qld-dark-grey : $core-default-color-neutral-darker;
$qld-medium-grey : $core-default-color-neutral-dark;
$qld-alt-grey : $core-default-color-neutral-light;
$qld-light-link: $color-default-color-light-link-default;

//Should be core color rather than second hand reference (no match)
$qld-soft-grey : $color-default-color-light-border-alt;

$qld-dark-grey-muted : $color-default-color-light-text-lighter; 
$qld-text-grey : $color-default-color-light-text-default;
$qld-hint-text-color-muted: $core-default-color-neutral-lighter;
$qld-color-dark-button-hover: $core-default-color-brand-primary-light-green;
$qld-text-headings : $color-default-color-light-text-heading;
$qld-grey : $core-default-color-neutral-light;
$qld-light-grey : $core-default-color-neutral-lighter;
$qld-light-grey-alt : $color-default-color-light-background-alt;
$qld-extra-light-grey : $core-default-color-neutral-lightest;
$qld-white : $core-default-color-neutral-white;
$qld-white-underline : $core-default-color-status-underline-light;
$qld-dark-underline : $core-default-color-status-underline-dark;
$qld-maroon-traditional: $core-default-color-brand-other-gov-maroon;
$qld-maroon-modern : $core-default-color-brand-other-modern-maroon;
$qld-notify-warning : $core-default-color-status-error-default;
$qld-notify-information: $core-default-color-status-info-default;
$qld-notify-success : $core-default-color-status-success-default;
$qld-notify-alert : $core-default-color-status-caution-default;
$qld-syntax-values : $core-default-color-brand-primary-light-green;
$qld-syntax-elements : $core-default-color-code-light-blue;
$qld-syntax-properties : $core-default-color-code-violet;
$qld-syntax-comments : $core-default-color-code-muted;
$qld-textbox-border-color: $color-default-color-light-action-primary-hover;
$qld-color-error-light: $core-default-color-status-error-lightest;
$qld-color-success-light: $core-default-color-status-success-lightest;

// Define new figma variables as SASS variables
$qld-color-background: $core-default-color-neutral-white;
$qld-color-background--shade: $color-default-color-light-background-default-shade;
$qld-color-light-background: $core-default-color-status-info-lightest;
$qld-color-light-background--shade: $color-default-color-light-background-light-shade;
$qld-color-light-alt-background: $color-default-color-light-background-alt;
$qld-color-light-alt-background--shade: $color-default-color-light-background-alt-shade;

$qld-color-dark-background: $core-default-color-brand-primary-sapphire-blue;
$qld-color-dark-background--shade: $color-default-color-dark-background-default-shade;
$qld-color-dark-alt-background: $core-default-color-brand-primary-dark-blue;
$qld-color-dark-alt-background--shade: $color-default-color-dark-background-alt-shade;

$qld-light-action-primary: $qld-sapphire-blue;
$qld-light-action-primary-hover: $color-default-color-light-action-primary-hover;
$qld-light-action-secondary: $color-default-color-light-action-secondary;
$qld-light-action-secondary-hover: $color-default-color-light-action-secondary-hover;

$qld-dark-action-primary: $color-default-color-dark-action-primary;
$qld-dark-action-primary-hover: $color-default-color-dark-action-primary-hover;
$qld-dark-action-secondary: $core-default-color-brand-secondary-golden-yellow;
$qld-dark-action-secondary-hover: $color-default-color-dark-action-secondary-hover;
$qld-dark-action-text: $color-default-color-dark-link-on-action;

$primary : $qld-brand-primary;
$primary-dark : $qld-brand-primary-dark;
$secondary : $qld-dark-green;
$success : $qld-notify-success;
$info : $qld-notify-information;
$warning : $qld-notify-alert;
$danger : $qld-notify-warning;
$light : $qld-extra-light-grey;
$dark : $qld-dark-grey;

$qld-fluid-width-full: 100%;
$qld-fluid-width-3-quarters: 75%;
$qld-fluid-width-half: 48.5%;
$qld-fluid-width-1-quarter: 25%;

// The Bootstrap map-merge will create custom CSS variables against the ::root node.
// These will be available to all components via the PREFIXED variable, for example: var(--qld-dark-green)
// The PREFIX is defined at the top of main.SCSS file (ie CSS entry point)

$project-colors: (
  "brand-primary-dark": $qld-brand-primary-dark,
  "brand-primary": $qld-brand-primary,
  "brand-primary-light": $qld-brand-primary-light,
  "brand-secondary": $qld-brand-secondary,
  "brand-secondary-dark": $qld-brand-secondary-dark,
  "brand-secondary-light": $qld-brand-secondary,
  "brand-accent": $qld-brand-accent,
  //...
  "dark-blue": $qld-dark-blue,
  "sapphire-blue": $qld-sapphire-blue,
  "light-blue": $qld-light-blue,
  "light-green": $qld-light-green,
  "dark-green": $qld-dark-green,
  "golden-yellow": $qld-golden-yellow,
  "system-darkest": $qld-system-darkest,
  "text-grey": $qld-text-grey,
  "dark-grey": $qld-dark-grey,
  "dark-grey-muted": $qld-dark-grey-muted,
  "soft-grey": $qld-soft-grey,
  "medium-grey": $qld-medium-grey,
  "grey": $qld-grey,
  "alt-grey": $qld-alt-grey,
  "light-grey": $qld-light-grey,
  "light-grey-alt": $qld-light-grey-alt,
  "extra-light-grey": $qld-extra-light-grey,
  "white": $qld-white,
  "white-underline": $qld-white-underline,
  "dark-underline": $qld-dark-underline,
  "maroon-traditional": $qld-maroon-traditional,
  "maroon-modern": $qld-maroon-modern,
  "notify-warning": $qld-notify-warning,
  "notify-information": $qld-notify-information,
  "notify-success": $qld-notify-success,
  "notify-alert": $qld-notify-alert,
  "syntax-values": $qld-syntax-values,
  "syntax-elements": $qld-syntax-elements,
  "syntax-properties": $qld-syntax-properties,
  "syntax-comments": $qld-syntax-comments,
  "primary": $qld-sapphire-blue,
  "primary-dark": $qld-brand-primary-dark,
  "secondary": $qld-dark-green,
  "success": $qld-notify-success,
  "info": $qld-notify-information,
  "warning": $qld-notify-alert,
  "danger": $qld-notify-warning,
  "light": $qld-extra-light-grey,
  "dark": $qld-dark-grey,
  "dark-text": $qld-dark-text,
  "dark-border": $qld-dark-border,
  "dark-alt-border": $qld-dark-alt-border,
  "alt-button-hover": $qld-alt-button-hover,
  "dark-blue-shade": $qld-dark-blue-shade,
  "dark-focus": $qld-dark-focus,
  "text-light-blue-dark": $qld-text-light-blue-dark,
  "light-green-dark": $qld-light-green-dark,
  "dark-green-dark": $qld-dark-green-dark,
  "light-yellow": $qld-light-yellow,
  "button-dark-blue": $qld-button-dark-blue,
  "text-darkest": $qld-text-darkest,
  "dark-hover" : $qld-dark-hover,
  "light-action-primary": $qld-light-action-primary,
  "light-action-primary-hover": $qld-light-action-primary-hover,
  "light-action-secondary":  $qld-light-action-secondary,
  "light-action-secondary-hover":  $qld-light-action-secondary-hover,
  "dark-action-primary": $qld-dark-action-primary,
  "dark-action-primary-hover": $qld-dark-action-primary-hover,
  "dark-action-text": $qld-dark-action-text,
  "dark-action-secondary": $qld-dark-action-secondary,
  "dark-action-secondary-hover": $qld-dark-action-secondary-hover,

  //Define new BEM colour variables from Figma
  //These will become properties on ::root node that you can reference by e.g: background: var(--qld-light-text-text)
  "default-background": $qld-color-background,
  "default-background-shade": $qld-color-background--shade,
  "light-text-heading": $qld-text-headings,
  "light-text-text": $qld-text-grey,
  "light-background": $qld-light-background,
  "light-background-shade": $qld-light-background-shade,
  "light-border": $qld-light-border,
  "light-link": $qld-light-link,

  "light-alt-background": $qld-color-light-alt-background,
  "light-alt-background-shade": $qld-color-light-alt-background--shade,
  "dark-background": $qld-color-dark-background,
  "dark-background-shade": $qld-color-dark-background--shade,
  "dark-alt-background": $qld-color-dark-alt-background,
  "dark-alt-background-shade": $qld-color-dark-alt-background--shade,
  "link-visited": $qld-link-visited,
  "link-visited-dark": $qld-link-visited-dark,
);

// Merge maps
$tokens: map-merge($theme-colors, $qld-tokens);
$theme-colors: map-merge($tokens, $project-colors);

// Cusotm GRID breakpoints: https://getbootstrap.com/docs/5.2/layout/grid/#variables
$grid-breakpoints: (
  xs: 0,
  sm: 400px,
  md: 700px,
  lg: 992px,
  xl: 1312px
);

// Default border radius
$border-radius: 0.25rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.25rem;

// QGOV Borders
$qld-border-width-thin : 1px;
$qld-border-width-default : 2px;
$qld-border-width-medium : 3px;
$qld-border-width-thick : 4px;
$qld-border-width-extra-thick : 8px;

// Extended spacers to procude 8px and 16px increments
// use px-16, py-sm-16, ml-40, mr-40, etc
$qld-custom-spacers: (
  16: $spacer,
  20: ($spacer * 1.25),
  24: ($spacer * 1.5),
  32: ($spacer * 2),
  40: ($spacer * 2.5),
  48: ($spacer * 3.0),
  56: ($spacer * 3.5),
  64: ($spacer * 4),
  72: ($spacer * 4.5),
  80: ($spacer * 5)
);

$spacers: map-merge($spacers, $qld-custom-spacers);